import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    socketConnection: null
}
export const SocketSlice = createSlice({
    name: 'socket',
    initialState,
    reducers: {
        openSocket: (state, action) => {
            state.socketConnection = action.payload
        },
        closeSocket: (state) => {
            state.socketConnection = null
        }
    },
})

// Action creators are generated for each case reducer function
export const { openSocket, closeSocket } = SocketSlice.actions

export default SocketSlice.reducer