import { createSlice } from '@reduxjs/toolkit'

const initialState = {
activeTicket: null
}
export const TicketSlice = createSlice({
    name: 'ticket',
    initialState,
    reducers: {
        handleTicket: (state, action) => {
            state.activeTicket = action.payload
        },
       
    },
})


export const { handleTicket } = TicketSlice.actions

export default TicketSlice.reducer