import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ActiveScreen: 'Dashboard',
};

export const ActiveScreen = createSlice({
  name: "ActiveScreen",
  initialState,
  reducers: {
    Activescreen: (state, action) => {
      state.ActiveScreen = action.payload;
    },
  },
});

export const { Activescreen } = ActiveScreen.actions;

export default ActiveScreen.reducer;
