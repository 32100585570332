import axios from "axios";
import { store } from "../Slices";

// Function to get token from Redux store
const getToken = () => {
  const token = store.getState()?.data?.userInfo?.data;
  return token?.token || null;
};

const callApi = async (endpoint, method, payload, malter = false, objnew = false) => {
  // Get the token
  const token = getToken();

  // Set the authorization headers
  const authHeaders = token
    ? {
        Authorization: `Bearer ${token}`,
      }
    : {};

  let configaxios;

  if (malter) {
    const formData = new FormData();

    // Append files and other payload data to FormData
    payload?.files?.forEach((file) => {
      formData.append("files", file);
    });
    Object.keys(payload || {}).forEach((key) => {
      if (key !== "files") {
        formData.append(key, payload[key]);
      }
    });

    configaxios = {
      method,
      url: endpoint,
      data: formData,
      headers: {
        Accept: "*/*",
        "Content-Type": "multipart/form-data",
        ...authHeaders,
      },
    };
  } else {
    configaxios = {
      method,
      url: endpoint,
      data: payload,
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        ...authHeaders,
      },
    };

    // Set responseType to "blob" if 'objnew' is true
    if (objnew) {
      configaxios.responseType = "blob";
    }
  }

  // Make the API call
  return new Promise((resolve, reject) => {
    axios(configaxios)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error?.response?.data || { message: "Request failed" });
      });
  });
};

const callPublicApi = (endpoint, method, payload) => {
  const configaxios = {
    method,
    url: `${process.env.REACT_APP_AuthenticationService}${endpoint}`,
    data: payload,
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
    },
  };

  return new Promise((resolve, reject) => {
    axios(configaxios)
      .then((res) => {
        if (res?.data) {
          resolve(res.data);
        } else {
          reject({ message: "No data returned from API" });
        }
      })
      .catch((error) => {
        reject({
          message: error?.response?.data?.message || "Something went wrong, please re-login",
          statusCode: error?.response?.status || 500,
        });
      });
  });
};

export { callApi, callPublicApi };
