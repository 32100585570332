import { configureStore } from "@reduxjs/toolkit";
import SocketSlice from "./SocketSlice";
import AuthSlice from "./AuthSlice";
import DataSlice from "./DataSlice";
import TicketSlice from "./TicketSlice";
import  ActiveScreen  from "./Activescreen";

export const store = configureStore({
  reducer: {
    socket: SocketSlice,
    data: DataSlice,
    auth: AuthSlice,
    ticket: TicketSlice,
    activeScreen: ActiveScreen,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),

  devTools: true,
});
