import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  
};

export const DataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    addData: (state, action) => {     
      state[action.payload.name] = action.payload.data;
    },
  },
});

export const { addData } = DataSlice.actions;

export default DataSlice.reducer;
