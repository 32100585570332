import { createSlice } from '@reduxjs/toolkit'

const initialState = {

        auth:{
            token:false,
        rights:[],
        }
    
}

export const AuthSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuth: (state, action) => {          
            state.auth.token=action.payload
            
        },

    },
})

export const { setAuth } = AuthSlice.actions

export default AuthSlice.reducer